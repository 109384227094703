.shared-header__account-area {
  display: flex;

  .user-action-dropdown {
    @include bp(1111px) {
      display: none;
    }
    .username {
      display: flex;
      gap: 8px;
      align-items: center;
      cursor: pointer;
      font-size: 16px;
      color: $navTextColor;

      ._icon {
        svg {
          height: 20px;
          width: 20px;
        }
      }
    }
  }

  &.-mobile {
    flex-direction: column;
    margin-bottom: 24px;
  }

  .link-wrapper {
    display: flex;
    align-items: center;
  }

  ._link {
    display: flex;
    align-items: center;

    font-size: 14px;
    font-style: normal;
    font-weight: normal;
    color: $navTextColor;
    letter-spacing: 0.1px;
    text-decoration: none;
    white-space: nowrap;

    .username {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 280px;

      @include minbp(1111px) {
        max-width: 124px;
      }
    }

    &.-mobile-only {
      @include minbp(1111px) {
        display: none;
      }
    }

    > ._icon {
      flex-shrink: 0;
      margin-right: 8px;
      align-items: center;
      height: 20px;
      width: 20px;

      > svg {
        width: 100%;
        height: 100%;
      }
    }
  }

  &.-mobile {
    .link-wrapper,
    ._link {
      &:not(:last-child) {
        margin-bottom: 24px;
      }
    }

    .shared-icon-button,
    ._link {
      ._label,
      &,
      * > ._label {
        font-size: 16px;
        line-height: 24px;
        font-weight: 600;
        color: $navTextColor;
      }

      > ._icon,
      > * > ._icon {
        height: 20px;
        width: 20px;
      }
    }
  }
}
