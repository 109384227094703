.heading {
  display: inline-block;

  &.underline {
    @include heading-underline;
  }

  &.h1 {
    @include heading-h1;
  }

  &.h2 {
    @include heading-h2;
  }

  &.h3 {
    @include heading-h3;
  }

  &.h4,
  &.h5,
  &.h6 {
    @include heading-subtitle;
  }
}
