.content__text-image-section {
  display: flex;
  flex-wrap: wrap;
  margin: 38px -24px;

  @include bp(1100) {
    flex-direction: column;
    margin: 38px 0;
  }

  .textimage {
    flex: 1;
    display: flex;
    margin: 0 24px;
    flex-direction: column;
    min-width: calc(33% - 48px);

    @include bp(1100) {
      margin-bottom: 40px;
    }

    @include bp($mobile) {
      margin: 48px 0 0;
    }

    .heading {
      margin-top: 24px;
      text-align: center;
    }

    .description {
      @include body-large;
      margin-top: 16px;
      text-align: center;
    }

    .image-wrapper {
      object-fit: contain;

      @include bp($mobile) {
        margin: 0 auto;
      }

      img {
        max-width: 100%;
      }
    }
  }
}
