.popup-alerts {
  position: relative;
  width: 100%;
  transform: translateX(-50%);
  position: absolute;
  left: 50%;
  top: calc(#{$headerDesktopHeight} + 42px);

  max-width: 1440px;
  z-index: 5;

  padding: 0 10px;
}
