.station-wrapper {
  display: flex;
  border-radius: 16px;
  background-color: $white;
  box-shadow: $shadowMedium;
  max-width: 302px;
  min-width: 302px;
  overflow: hidden;
  margin-bottom: 24px;
  margin-right: 24px;

  @include bp($mobile) {
    min-width: 270px;
    width: 100%;
  }
  .content {
    padding: 16px 16px 40px;
    text-align: left;
    .name {
      @include body-large-strong;
    }
    .label {
      font-weight: 600;
      color: $black;
    }
    > div:not(:last-child) {
      margin-bottom: 17px;
    }
  }
  .station {
    cursor: pointer;
    .image-wrapper {
      overflow: hidden;
      height: 180px;
    }
    .address {
      display: flex;
      align-items: center;
      .street {
        margin-left: 16px;
        color: $black;
      }
    }
    img {
      width: 302px;
      height: 100%;
      object-fit: cover;
      object-position: center;
      transition-duration: 600ms;
      -webkit-backface-visibility: hidden;
      backface-visibility: hidden;
    }
    &:hover {
      .image-wrapper {
        img {
          transform: scale(1.15);
        }
      }
    }
  }

  .services {
    display: flex;
    flex-wrap: wrap;
    > .service:not(:last-child) {
      margin-right: 14px;
      margin-bottom: 10px;
    }
  }
  .service {
    width: fit-content;

    .icon {
      width: 24px;
      height: 24px;
    }
  }
}
