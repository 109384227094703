.footer__region {
  position: relative;
  padding-top: 40px;
  padding-bottom: 40px;
  overflow: hidden;

  @include minbp($mobile) {
    display: flex;
  }

  @include minbp($mobileXS) {
    padding-right: 24px;
    padding-left: 24px;
  }

  // Divider
  &:before {
    content: ' ';
    position: absolute;
    left: 0;
    top: 0;
    right: 0;

    height: 3px;
    background-color: $gray2;

    @include bp($mobile) {
      height: 2px;
      background-color: $gray2;
    }
  }

  .footer__region__list {
    margin: 0;
    padding: 0;
    list-style-type: none;
  }

  .footer__region__list li {
    font-size: 18px;
    line-height: 32px;
    color: $black;
    font-weight: 400;

    @include bp($mobile) {
      font-size: 16px;
      line-height: 24px;
      color: $black;
      font-weight: 400;
    }
  }

  .footer__region__link,
  .footer__region__action {
    cursor: pointer;
    color: $black;

    &,
    span.icon,
    img.image {
      display: flex;
      align-items: center;
    }

    svg,
    img {
      margin-right: 8px;
      max-height: 24px;
      max-width: 24px;
    }
  }

  .footer__region__list.social {
    display: flex;
    flex-wrap: wrap;
    order: 2;
    flex-direction: row;

    @include minbp($mobileXS) {
      flex-direction: column;
    }

    @include minbp($mobile) {
      flex-direction: row;
    }

    @include minbp(1200) {
      margin-left: 24px;
      align-self: center;
    }

    li {
      margin: 8px 0;

      &:not(:last-child) {
        margin-right: 24px;
      }
    }
  }

  .footer__region__list.contact {
    display: flex;
    flex-direction: row;
    align-items: center;

    @include bp($mobile) {
      flex-direction: column;
      align-items: flex-start;
      order: 1;
    }

    li:not(:last-child) {
      margin-right: 24px;
      @include bp($mobile) {
        margin-bottom: 24px;
      }
    }
  }

  .footer__region__list.actions {
    display: flex;
    justify-content: center;
    flex-direction: column;

    @include bp($mobile) {
      order: 3;
      flex: 100%;
    }

    li:not(:last-child) {
      margin-bottom: 8px;
    }
  }

  .footer__region__logo {
    align-self: center;

    > img {
      height: 36px;

      @include bp($mobile) {
        height: 24px;
      }
    }

    @include bp($mobile) {
      margin-top: 80px;
    }
  }

  .footer__region__content {
    flex: 1;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    flex-direction: column;

    @include minbp($mobile) {
      margin-right: 50px;
    }

    @include minbp($mobileXS) {
      flex-direction: row;
    }

    @include minbp($mobile) {
      flex-direction: column;
    }

    @include bp(1200) {
      gap: 24px;
    }

    @include minbp(1200) {
      flex-direction: row;
    }
  }
}
