.shared-header__right-buttons {
  display: flex;
  align-items: center;
  flex-shrink: 0;

  > :not(:last-child) {
    margin-right: 24px;
  }

  &.-desktop {
    > button {
      margin-left: 24px;
    }

    @include bp(1111px) {
      display: none;
    }
  }

  &.-mobile {
    flex-direction: column-reverse;
    align-items: flex-start;
    margin-bottom: 64px;
    order: -1;
  }
}
