.button {
  @include body-regular-strong();
  border: none;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  text-align: center;
  padding: 12px 48px;

  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 16px;

  &:not(:disabled):hover {
    opacity: 0.8;
  }

  &.squared {
    border-radius: 0;
  }

  &.semi-rounded {
    border-radius: 8px;
  }

  &.primary {
    background-color: $primary;
    color: $white;

    &:hover,
    &:focus {
      background-color: $black;
    }
  }

  &.secondary {
    border-width: 1px;
    border-style: solid;

    &:not(.inverted) {
      border-color: $white;
      color: $white;

      &:hover {
        border-color: $black;
      }
    }

    &.inverted {
      color: $black;
      border-color: $black;

      &:hover {
        color: $white;
        border-color: $black;
        background-color: $black;
      }
    }
  }

  &.ghost {
    background: transparent;
    padding: 0;
    color: $black;

    svg:only-child {
      height: 100%;
    }
  }

  &:disabled {
    cursor: not-allowed;
  }
}
