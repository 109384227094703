.content__images-section {
  margin: 48px 0 0;
  display: flex;

  img {
    object-fit: cover;
    max-width: 100%;
    border-radius: 16px;
    max-height: 432px;
    aspect-ratio: 3/2;
  }

  &.single-image img {
    min-width: 100%;
  }

  &.multiple-images {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    margin: 48px -12px 0px -12px;

    @include bp($mobile) {
      flex-wrap: nowrap;
      overflow-x: auto;
      margin: 48px 0 0;

      img {
        margin: 0 15px 0 0;

        &:last-child {
          margin-right: 0;
        }
      }
    }

    img {
      &:not(:last-child:nth-child(odd)) {
        margin: 24px 12px 0;
        max-width: calc(50% - 24px);

        @include bp($mobile) {
          max-width: 296px;
          max-height: 191px;
        }
      }

      &:last-child:nth-child(odd) {
        max-width: calc(100% - 24px);
        margin: 24px 12px 0;
        min-width: calc(100% - 24px);

        @include bp($mobile) {
          max-width: 296px;
          max-height: 191px;
          min-width: unset;
        }
      }
    }
  }
}
