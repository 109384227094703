.accordion-wrapper {
  display: flex;
  flex-direction: column;
  margin: 16px 0;
  background-color: $white;
  border-radius: 16px;
  box-shadow: $shadowMedium;
  padding: 24px;
  cursor: pointer;
  width: 100%;

  .accordion {
    color: #000000;
    display: flex;
    align-items: center;
    transition: background-color 0.6s ease;
    width: 100%;
  }

  .accordion-title {
    font-weight: 800;
    font-size: 16px;
    line-height: 18px;
    padding-right: 16px;
    @include body-large-strong;
    text-align: left;
  }
  .accordion-icon {
    transition: 0.6s;
    margin-left: auto;
    min-width: 16px;
    min-height: 16px;
  }

  .accordion-content {
    overflow: hidden;
    transition: max-height 0.5s ease;
  }

  .accordion-text {
    @include body-large;
    padding: 24px 0 0;
    white-space: pre-wrap;
    text-align: left;
    a {
      text-decoration: none;
      @include body-large-strong;
      color: $primary;
    }
    p {
      @include body-large;
    }
    img {
      width: 100%;
      height: 100%;
    }
  }
}
