._modal-container {
  position: fixed;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  ._modal {
    background-color: white;
    border-radius: 4px;
    box-shadow: $turnitShadow;
    position: relative;
    z-index: 1001;
    overflow-y: auto;

    > ._close-modal {
      position: absolute;
      top: 8px;
      right: 8px;
      height: 36px;
      width: 36px;
      cursor: pointer;
      border: 0;
      background-color: transparent;

      // IOS Fix
      padding: 0;
    }
  }
}
