.content-page {
  h4 {
    @include heading-subtitle;
  }

  h3 {
    @include heading-h3;
  }
  .hero-banner {
    width: 100%;
    max-height: 400px;
    object-fit: cover;
    object-position: top center;
    margin: 56px 0 80px;
    border-radius: 16px;
    @include bp($mobile) {
      height: 128px;
      border-radius: 0;
      width: calc(100% + 48px);
      margin: -24px -24px 32px;
    }
  }
  a {
    @include body-large-strong;
    color: $primary;
  }

  .content-layout__body {
    > :first-child {
      margin-top: 0;
    }
    .banners {
      margin-top: 80px;
    }
  }
}
