.menu-item__icon {
  display: flex;
  align-items: center;
  color: $black;

  img {
    // Default margin
    margin-right: 8px;
  }

  &:hover {
    &,
    > * {
      cursor: pointer;
      color: $primary;
    }
  }
}
