.body {
  &,
  p,
  li,
  span {
    &:not(.strong) {
      &:not(.large):not(.small) {
        @include body-regular;
      }

      &.small {
        @include body-small;
      }

      &.large {
        @include body-large;
      }
    }

    &.strong:not(.large) {
      &:not(.large):not(.small) {
        @include body-regular-strong;
      }

      &.small {
        @include body-small-strong;
      }

      &.large {
        @include body-large-strong;
      }
    }
  }
}
