main.error-page {
  min-height: 20vh;
  margin-top: 36px;

  .error-page__content {
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;

    .body {
      text-align: center;
      max-width: 330px;
      margin: 30px 0 40px;
    }
  }
}
