.hero__background {
  &.-mobile {
    @include minbp($mobile) {
      display: none !important;
    }
  }

  &.-desktop {
    @include bp($mobile) {
      display: none !important;
    }
  }

  img,
  video {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;

    height: 100%;
    width: 100%;
    object-fit: cover;
  }
  video {
    object-fit: cover;
  }
}
