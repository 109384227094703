// ------------------------------
// Colors
// ------------------------------

// Hardcoded colors
$background: #fafafa;

// Configurable colors
$primary: var(--primary);
$black: var(--black);
$white: var(--white);
$gray1: var(--gray1);
$gray2: var(--gray2);
$gray3: var(--gray3);
$gray4: var(--gray4);
$gray5: var(--gray5);
$secondary: var(--secondary);
$warning: var(--warning);
$danger: var(--danger);
$success: var(--success);
$navBackgroundColor: var(--nav-background-color);
$navTextColor: var(--nav-text-color);
$navIconColor: var(--nav-icon-color);
$searchTitleColor: var(--search-title-color);

// ------------------------------
// Breakpoints
// ------------------------------
$laptop: 1440px;
$tabletLandscape: 1300px;
$tablet: 900px;
$mobile: 768px;
$mobileXS: 480px;

// ------------------------------
// Animations
// ------------------------------
$transition: cubic-bezier(0.6, 0.4, 0.1, 0.9);

// ------------------------------
// Box-shadows
// ------------------------------
$shadowMedium: 0px 4px 16px rgba(0, 0, 0, 0.1);
$turnitShadow: 0px 22px 34px 0px rgba(0, 16, 34, 0.1);

// ------------------------------
// Sizes
// ------------------------------
$headerDesktopHeight: 84px;
$headerMobileHeight: 80px;
