@mixin heading-h1($color: $black) {
  font-size: 48px;
  line-height: 52px;
  color: $color;
  font-weight: 600;
}

@mixin heading-h2-mobile($color: $black) {
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px;
  letter-spacing: 0;
}

@mixin heading-h2($color: $black) {
  font-size: 32px;
  line-height: 40px;
  color: $color;
  font-weight: 600;

  @include bp($mobile) {
    @include heading-h2-mobile($color);
  }
}

@mixin heading-h3($color: $black) {
  font-size: 24px;
  line-height: 32px;
  color: $color;
  font-weight: 600;
}

@mixin heading-subtitle($color: $black) {
  line-height: 32px;
  font-size: 20px;
  color: $color;
  font-weight: 600;
}

@mixin heading-pre-title($color: $black) {
  line-height: 20px;
  font-size: 14px;
  color: $color;
  font-weight: 600;
  letter-spacing: 0.04em;
  text-transform: uppercase;
}

@mixin body-large($color: $black) {
  font-size: 18px;
  line-height: 32px;
  color: $color;
  font-weight: 400;
}

@mixin body-large-strong($color: $black) {
  font-size: 18px;
  line-height: 24px;
  color: $color;
  letter-spacing: 0.1px;
  font-weight: 600;
}

@mixin body-regular($color: $black) {
  font-size: 16px;
  line-height: 24px;
  color: $color;
  font-weight: 400;
}

@mixin body-regular-strong($color: $black) {
  font-size: 16px;
  line-height: 26px;
  color: $color;
  font-weight: 600;
}

@mixin body-small($color: $black) {
  font-size: 14px;
  line-height: 18px;
  color: $color;
  font-weight: 400;
}

@mixin body-small-strong($color: $black) {
  font-size: 14px;
  line-height: 18px;
  color: $color;
  font-weight: 600;
}

@mixin heading-underline($color: $primary) {
  position: relative;
  padding-bottom: 14px;

  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    height: 6px;
    background: $primary;

    @include minbp($mobile) {
      left: -32px;
    }
  }
}

@mixin wysiwyg($color: $white) {
  h1 {
    @include heading-h1($color);
  }

  h2 {
    @include heading-h2($color);
  }

  h3 {
    @include heading-h3($color);
  }

  h4,
  h5,
  h6 {
    @include heading-subtitle($color);
  }

  p,
  span,
  li {
    @include body-regular($color);
  }
}
