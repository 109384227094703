.alert {
  border-radius: 16px;
  padding: 16px 24px;

  position: relative;
  display: flex;
  flex-direction: column;
  will-change: transform;

  &.-info {
    background-color: $black;
    color: $white;

    &:hover.-has-action {
      transition: transform 300ms $transition;
      transform: scale(1.005);
    }
  }
  .alert__actions {
    display: flex;
    width: 100%;
  }
  &.-has-action {
    cursor: pointer;
  }

  .alert__icon {
    margin-right: 12px;
    height: 24px;
    float: left;
    svg {
      height: 24px;
      width: 24px;
    }
  }

  .alert__title {
    @include body-regular-strong($white);
  }

  .alert__close {
    z-index: 5;
    margin-left: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    svg {
      color: $white;
      opacity: 0.8;
      will-change: transform;
      transition-duration: 300ms;
      transition-property: $transition;
      transition-timing-function: transform;
    }

    &:hover {
      svg {
        opacity: 1;
        transform: scale(1.2);
      }
    }
  }

  &:not(:first-child) {
    margin-top: 24px;
  }
}

.alert__body {
  @include body-regular();
  margin-top: 4px;

  &.-info {
    color: $white;
  }
}
