.content__links-section {
  display: flex;
  flex-direction: row;
  margin: 0 -8px;
  flex-wrap: wrap;

  @include bp($mobile) {
    flex-direction: column;
  }

  .pre-title {
    @include heading-pre-title($gray4);
    margin-bottom: 16px;
  }

  .image {
    margin-right: 24px;
    object-fit: cover;
    width: 128px;
    border-radius: 16px;
    object-position: center;
    max-height: 98px;
    min-height: 88px;

    @include bp($mobile) {
      height: 100%;
      min-height: 88px;
      max-height: 100%;
    }
  }

  .icon {
    margin-right: 18px;
  }

  .text {
    @include body-regular-strong;

    @include bp($mobile) {
      @include body-small-strong;
    }
  }

  > :not(:last-child:nth-child(odd)) {
    flex: 1 1 0;
  }
  > :last-child:nth-child(odd) {
    flex-basis: 100%;
  }

  .link {
    margin: 24px 8px 0;
    padding: 24px 30px 24px 24px;
    box-shadow: $shadowMedium;
    border-radius: 16px;
    flex-grow: 1;
    margin-top: 24px;
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    min-width: 345px;

    &.with-image {
      padding: 24px 30px 24px 24px;
    }

    @include bp($mobile) {
      min-width: initial;
    }
  }

  .icon-wrapper {
    display: flex;
    margin-left: auto;
    padding-left: 16px;
  }
}
