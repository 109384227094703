.collapsible {
  border-radius: 4px;
  margin-top: 8px;
  background-color: #fff;
  will-change: max-height;

  .collapsible-content-wrapper {
    width: 100%;
  }

  .collapsible-label {
    height: 80px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 24px 0;

    color: $black;
    font-style: normal;
    font-weight: normal;
    font-size: 19px;
    line-height: 24px;
    letter-spacing: 0.1px;
    width: 100%;

    &.btn {
      cursor: pointer;
    }

    &.open {
      border-bottom: 1px solid #dce0e6;
    }

    ._icon {
      width: 32px;
      height: 32px;
      margin: 0 30px 0 42px;
    }
    ._label {
      width: 100%;
      min-width: 0px;
    }
  }

  .collapsible-chevron {
    height: 24px;
    width: 24px;
    flex-shrink: 0;
    @include minbp($mobile) {
      margin-left: 24px;
    }
  }

  .chevron-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    outline: none;

    svg {
      transform-origin: center;
      outline: none;
    }
  }
}
