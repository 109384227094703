.menu-popdown {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background: $background;
  box-shadow: 0 6px 6px -6px rgba(#000, 0.16);
  border-top: 1px solid #f2efea;
  z-index: 100;
  display: flex;
  overflow: hidden;
  display: flex;
  flex-direction: column;

  .menu-item-wrapper {
    margin: 16px 0 0;

    &,
    > *,
    > * > * {
      font-size: 18px;
      color: $black;
      letter-spacing: 0.1px;
      font-weight: 600;
      line-height: 22px;
      text-align: left;
    }
  }

  .close-button {
    position: absolute;
    bottom: 56px;
    right: 56px;
    color: $black;
    cursor: pointer;
    background: none;
    border: none;
  }

  // Pop down
  &.anim-enter {
    opacity: 0;
    transform: scaleY(0);
    transform-origin: top center;
  }

  &.anim-enter-active {
    opacity: 1;
    transform: scaleY(1);
    transform-origin: top center;
    transition: all 250ms $transition;
  }

  &.anim-exit {
    opacity: 1;
    transform: scaleY(1);
    transform-origin: top center;
  }

  &.anim-exit-active {
    opacity: 0;
    transform: scaleY(0);
    transform-origin: top center;
    transition: all 250ms $transition;
  }

  .max-width {
    display: flex;
    flex-direction: row;
    width: 100%;
    padding: 0 56px;
    @include bp(1300) {
      flex-direction: column;
    }
  }

  ._links-area {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    padding-bottom: 32px;
    padding-left: 0;
    margin: 0;
    list-style: none;

    .menu-item__text_icon {
      img {
        height: 48px;
        width: 48px;
        margin-right: 24px;
        margin-left: -72px;
      }
    }

    .menu-item-wrapper {
      margin-left: 72px;
      min-width: 190px;
    }

    .menu-item-children {
      margin-top: 16px;
      margin-bottom: 0;
      padding-left: 0;
      list-style: none;

      &,
      > * {
        font-size: 16px;
        line-height: 24px;
        color: $black;
        font-weight: 400;
      }

      .menu-item__text_icon img {
        display: none;
      }

      ::before {
        content: '';
        position: absolute;
        left: 0px;
        width: 7px;
        height: 7px;
        background-color: $primary;
        border-radius: 50%;
        top: 50%;
        transform: translate(0, -50%);
        transition: transform 250ms ease, opacity 250ms ease;
        opacity: 0;
      }

      > * {
        margin-bottom: 16px;
      }

      > a {
        transition: transform 250ms ease, opacity 250ms ease, color 250ms ease;

        &:hover {
          transform: translateX(20px);
          transition-duration: 400ms;
          color: $primary;

          &::before {
            transform: translate(-20px, -50%);
            animation-duration: 400ms;
            animation-name: hoverAnim;
            opacity: 1;
          }
        }
      }
    }

    .menu-item-wrapper {
      width: calc(25% - 110px);
      margin-right: 30px;

      .menu-item__text,
      .menu-item__text_icon {
        display: flex;
        align-items: center;
      }

      > :first-child {
        height: 48px;
      }
    }
  }
}

@keyframes hoverAnim {
  0% {
    transform: translate(0, -50%);
    opacity: 0;
  }
  100% {
    transform: translate(-20px, -50%);
    opacity: 1;
  }
}
