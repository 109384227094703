.banner {
  position: relative;
  overflow: hidden;
  margin-bottom: 32px;
  border-radius: 16px;
  -webkit-mask-image: -webkit-radial-gradient(white, black);
  $bannerBackgroundGradient: linear-gradient(90deg, rgba(0, 0, 0, 0.73) 0%, rgba(0, 0, 0, 0) 102.01%);
  -webkit-clip-path: inset(0 0 0 0 round 16px);
  clip-path: inset(0 0 0 0 round 16px);

  .banner__background {
    position: absolute;
    z-index: 1;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;

    transform: scale(1);
    transition: transform 300ms ease-out;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
  }

  .banner__content {
    height: 100%;
    z-index: 2;
    -webkit-transform-style: preserve-3d;
    -webkit-backface-visibility: hidden;
    -webkit-mask-image: -webkit-radial-gradient(circle, white 100%, black 100%);
    padding: 32px;
    background: $bannerBackgroundGradient;

    display: flex;
    position: relative;
    flex-direction: column;

    .banner__title {
      @include heading-h3($white);
      margin-bottom: 8px;
    }

    .banner__body {
      @include body-regular($white);
    }

    .banner__action {
      margin-top: auto;
    }
  }
  @media (hover: hover) {
    &:hover,
    &:focus {
      .banner__background {
        transform: scale(1.1);
      }

      .banner-hover__content {
        opacity: 1;
        max-height: 126px;
      }
    }
  }
}

.banner-hover {
  background-color: $white;
  opacity: 0.9;
  padding: 16px 32px;

  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;

  .banner-hover__title {
    @include heading-h3;
    margin-bottom: 8px;
  }

  .banner-hover__body {
    @include body-regular;
  }

  .banner-hover__link {
    display: flex;
    align-items: center;
    align-self: flex-end;

    color: $primary;
    &:hover {
      color: $black;
    }
  }

  .banner-hover__content {
    max-height: 0;
    opacity: 0;
    transition: all 300ms ease;

    display: flex;
    flex-direction: column;
    @include bp($mobile) {
      max-height: initial;
      opacity: 1;
    }
  }
}

// TODO: Delete once button component is added
.banner-button {
  color: $white;
  display: inline-block;
  padding: 18px 52px;
  margin-top: 16px;
  border-radius: 16px;
  border: 1px solid $white;
  cursor: pointer;

  transition: all 300ms ease;

  &:hover {
    background: $black;
    border-color: $black;
    color: $white;
  }
}
