.destination-guide {
  margin-top: 120px;

  .departure {
    display: flex;
    align-items: center;
    .select-wrapper {
      margin-left: 12px;
      padding-bottom: 0;
      flex-basis: initial;
      width: unset;

      ._label {
        &:empty {
          margin-bottom: 0;
        }
      }
      .icon-wrapper {
        right: -20px;
        top: 50%;
        transform: translate(0, -50%);
        height: 16px;
      }
      select {
        padding: 0;
        border: none;
        color: $gray4;
        cursor: pointer;
        font-weight: 400;
        font-size: 24px;
        line-height: 32px;
        padding-right: 12px;
        height: 100%;
      }
    }
  }

  .departures {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    @include bp($tablet) {
      flex-direction: column;
    }

    &__seo-links {
      @include hidden();
    }
  }
  .departure__subheading {
    @include heading-h3;
  }

  .destination-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 40px;
    width: calc(25% - 70px);
    min-width: 200px;
    @include heading-h3;
    &:not(:last-child) {
      margin-right: 70px;
      @include bp(1100) {
        margin-right: 50px;
      }
    }
    @include bp(1400) {
      width: calc(33% - 70px);
      min-width: 220px;
    }
    @include bp(1100) {
      width: calc(50% - 70px);
      min-width: 250px;
    }
  }

  .stop-name {
    width: 100%;
    text-decoration: underline;
  }
  .destination-guide__heading {
    @include heading-h2;
    @include heading-underline;
    width: fit-content;
    margin-bottom: 80px;
    @include bp($mobile) {
      margin-bottom: 40px;
    }
  }

  .image-wrapper {
    height: 118px;
    width: 118px;
    min-height: 118px;
    min-width: 118px;
    border-radius: 80px;
    overflow: hidden;
    margin-right: 16px;
    img {
      height: 118px;
      width: 118px;
      object-fit: cover;
    }
  }
}
