.text-input {
  position: relative;

  &.disabled {
    .text-input__wrapper {
      border-color: $gray2;
      background-color: $gray2;
    }

    .text-input__input {
      color: $gray5;
    }

    .text-input__label {
      color: $gray5;
    }

    svg path {
      stroke: $gray5;
    }
  }

  .text-input__wrapper {
    position: relative;
    height: 48px;
    border-color: $black;
    border-bottom-style: solid;
    border-bottom-width: 2px;
    border-radius: 2px;
    overflow: hidden;
    transition: all 200ms ease;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 12px;
    background: $gray4;
  }

  .text-input__label {
    color: $gray1;
    font-weight: bold;
    font-size: 12px;
    line-height: 15px;
    text-transform: uppercase;
    display: block;
    margin-bottom: 7px;
    height: 15px;
  }

  .text-input__input {
    width: 100%;
    height: 100%;
    background: none;
    border: none;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
    color: $gray3;
    transition: color 200ms ease;
  }

  .text-input__icon {
    width: 19px;
    height: 19px;
  }

  .text-input__error {
    margin-top: 4px;
    position: absolute;
    top: 100%;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type='number'] {
    -moz-appearance: textfield;
  }
}
