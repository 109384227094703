.input-group {
  padding-bottom: 16px;
  max-height: 104px;
  max-width: 465px;
  flex-basis: calc(50% - 24px);
  width: 100%;

  @include bp($tablet) {
    flex-basis: 100%;
    margin-right: 0;
    max-width: 100%;
  }

  &.select-wrapper {
    position: relative;
    select {
      -webkit-appearance: none;
      appearance: none;
    }
    .icon-wrapper {
      position: absolute;
      top: 44px;
      right: 20px;
      color: $primary;
    }
  }
  ._label {
    width: 100%;
    @include heading-pre-title($gray5);
    margin-bottom: 6px;
    display: block;
  }
  &.error {
    ._input {
      border: 1px solid;
      border-color: $primary;
    }
  }

  ._input,
  select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;

    position: relative;
    background: $white;
    border: 1px solid;
    border-color: $gray3;
    border-radius: 4px;
    height: 48px;
    @include body-regular;
    padding: 12px 16px;
    width: 100%;

    &._error::placeholder {
      color: $primary;
    }

    &::placeholder {
      color: $gray2;
    }
  }
  > input {
    width: 100%;
    transition: all 0.4s ease;

    &._error {
      border: 1px solid;
      border-color: $primary;
    }
  }
}

._error-text {
  color: $primary;
  font-size: 12px;
  line-height: 12px;
  margin-top: 6px;
}
