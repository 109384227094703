.tippy-content {
  color: $black;
  background-color: $white;
  padding: 0;
  border-radius: 4px;
  box-shadow: $shadowMedium;

  .tooltip-content {
    .tooltip-close-wrapper {
      width: 100%;
      display: flex;
      justify-content: center;
      .tooltip-close-btn {
        position: absolute;
        opacity: 0;
        pointer-events: none;
        transition: opacity 150ms;

        border-radius: 50px;
        background-color: $white;

        bottom: 0;
        transform: translateY(50%);

        padding: 4px;
        display: flex;
        align-items: center;
        justify-content: center;

        svg {
          height: 22px;
          width: 22px;
        }

        &:focus {
          opacity: 1;
          pointer-events: all;
        }
      }
    }
  }
}
