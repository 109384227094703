@mixin bp-between($minWidth, $maxWidth) {
  @media all and (min-width: $minWidth) and (max-width: $maxWidth - 1px) {
    @content;
  }
}

@mixin bp($width) {
  @media all and (max-width: $width - 1px) {
    @content;
  }
}

@mixin minbp($width) {
  @media all and (min-width: 0px + $width) {
    @content;
  }
}

@mixin hp($height) {
  @media all and (max-height: $height - 1px) {
    @content;
  }
}

@mixin minhp($height) {
  @media all and (min-height: 0px + $height) {
    @content;
  }
}
