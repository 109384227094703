.shared-toast-container {
  position: fixed;
  right: 0;
  left: 0;
  top: 25px;
  pointer-events: none;
  z-index: 999999;

  max-height: 100px;

  > span {
    display: flex;
    flex-direction: column-reverse;
  }

  .rah-animating {
    transition: all 250ms ease-in-out;
  }
}
