.shared-mobile-popup {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  background: $navBackgroundColor;
  min-width: 320px;
  z-index: 1000;

  &.stations-side-panel {
    position: fixed;
    left: unset;
    max-width: 704px;

    @include bp(1024px) {
      max-width: 100%;
      left: 0;
      top: 0;
    }

    ._content-scroll-wrap {
      ._content {
        padding: 0;
      }
    }
  }

  &.-secondary,
  &.-tertiary {
    .header-menu__child {
      .menu-item__text {
        color: $gray5;
      }

      &,
      .menu-item__icon {
        color: $gray5;
      }
    }
  }

  &.-secondary {
    > div {
      background: $gray1;

      ._content {
        padding-top: 40px;
        background: $white;
        margin: 16px 0 0 16px;
        border-radius: 16px 0px 0px 0px;

        .menu-item__text_icon {
          display: flex;
          align-items: center;

          img {
            height: 24px;
            width: 24px;
            margin-right: 12px;
          }
        }
      }
    }
  }

  .buttons {
    display: flex;
    margin-bottom: 24px;
    margin-top: auto;

    > button {
      display: flex;
      cursor: pointer;
      margin-left: 24px;
      align-items: center;
      &:first-child {
        margin-left: 0;
      }

      > span {
        font-size: 14px;
        line-height: 18px;
        color: $black;
        font-weight: 400;
        margin-left: 8px;
      }
    }
  }

  &.-tertiary {
    > div {
      background: $gray1;

      ._content {
        padding: 0 0 40px;
        background: $background;
        margin: 16px 0 0 16px;
        border-radius: 16px 0px 0px 0px;

        .menu-mobile-popup-content {
          height: calc(100vh - 110px);
          padding-top: 40px;
          margin-left: 16px;
          background: $white;
          border-radius: 16px 0px 0px 0px;
          padding-left: 32px;

          .menu-item__text_icon img {
            display: none;
          }
        }
      }
    }
  }

  &.-sidebar {
    left: auto;

    @include bp($tablet) {
      left: 0;
      width: 100%;
    }
  }

  .popup-header-wrapper {
    height: $headerMobileHeight;

    .header-wrapper {
      display: flex;
      height: $headerMobileHeight;
      background: $navBackgroundColor;
      justify-content: space-between;
      align-items: center;
      padding: 0 16px;

      > ._logo {
        height: 48px;
        display: flex;
        flex-shrink: 1;
        min-width: 0;
        margin: 0 4px 0 0;
        padding: 9px 0;
        > img {
          object-fit: scale-down;
        }
        &._large {
          padding: 0;
          height: 40px;
        }
      }

      .close-button {
        color: $navIconColor;
        border: none;
        background: none;
      }
    }
  }

  &.popup--anim-enter {
    opacity: 0;
    transform: scale(0.9);
  }

  &.popup--anim-enter-active {
    opacity: 1;
    transform: none;
    transition: all 150ms ease;
  }

  &.popup--anim-exit {
    opacity: 1;
    transform: none;
  }

  &.popup--anim-exit-active {
    opacity: 0;
    transform: scale(0.9);
    transition: all 250ms ease;
  }

  &.popup-slide--anim-enter {
    opacity: 0;
    transform: translateX(50%);
  }

  &.popup-slide--anim-enter-active {
    opacity: 1;
    transform: translateX(0);
    transition: all 250ms ease;
  }

  &.popup-slide--anim-exit {
    opacity: 1;
    transform: translateX(0);
  }

  &.popup-slide--anim-exit-active {
    opacity: 0;
    transform: translateX(50%);
    transition: all 150ms ease;
  }

  ._content-scroll-wrap {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    -webkit-overflow-scrolling: touch;
    overflow-y: auto;
    height: 100vh;

    ._content {
      display: flex;
      flex-direction: column;
      flex: 1;
      overflow-y: auto;
      flex-basis: auto;
      padding: 56px 32px 40px;
      overflow-x: hidden;

      .divider {
        margin-top: auto;
        flex-shrink: 0;

        &--ltg {
          margin-left: -32px;
          width: calc(100% + 64px);
          min-height: 50px;
        }

        &--default {
          width: 100%;
          border-radius: 8px;
          height: 8px;
          background: linear-gradient(to right, $primary, rgba(255, 255, 255, 0));
          border: none;
        }
      }

      .menu-item__text_icon img {
        display: none;
      }

      .header-menu__item {
        display: block;
        font-size: 18px;
        line-height: 24px;
        color: $navTextColor;
        font-weight: 600;
        margin: 32px 0;

        > button {
          font-weight: 600;
        }

        &:first-child {
          margin-top: 0;
        }
      }

      ._popup-content {
        display: flex;
        flex-direction: column;
        flex: 1;
        padding: 24px;
        overflow: hidden;
      }
    }
  }
}
