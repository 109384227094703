@import 'normalize.css/normalize.css';
@import '@reach/skip-nav/styles.css';
@import 'tippy.js/dist/tippy.css';
@import 'swiper/swiper';
@import 'swiper/components/pagination/pagination';

// Shared
@import '../../shared/scss/entry.scss';

// Core
@import 'core/utils';
@import 'core/main';
@import 'core/input';

// common
@import 'common/hero/hero';

// Layouts
@import 'common/layout/content-layout';

// Pages
@import 'pages/page';
@import 'pages/error-page';
@import 'pages/home-page';
@import 'pages/faq-page';
@import 'pages/content-page';
@import 'pages/stations-page';
@import 'pages/business-form-page';
@import 'pages/feedback-form-page';

// Components
@import 'components/accordion';
@import 'components/accordion';
@import 'components/sidebar';
@import 'components/station';
@import 'components/side-panel-content';
@import 'components/button';
@import 'components/app_popup';

// Menu
@import 'components/menu/common-menu';

// Inputs
@import 'components/inputs/textinput';
@import 'components/inputs/input-group';
@import 'components/inputs/textarea';

// Banners
@import 'components/banners/banners';
@import 'components/banners/banner';

// Alerts
@import 'components/alerts/alert';
@import 'components/alerts/popup-alert';

// Heading
@import 'components/heading/heading';

// Body
@import 'components/body/body';

// Loaders
@import 'components/loaders/page-loader';

// Turnit
@import './common/turnit/search';

// Checkbox
@import './components/checkbox/checkbox';

// GDPR
@import './common/gdpr/cookie-notice';

// Pages-> Home
@import 'components/pages/home/destination-guide';

// Pages -> Content
@import 'components/pages/content/sections/wysiwyg-section';
@import 'components/pages/content/sections/images-section';
@import 'components/pages/content/sections/links-section';
@import 'components/pages/content/sections/steps-section';
@import 'components/pages/content/sections/divider-section';
@import 'components/pages/content/sections/button-section';
@import 'components/pages/content/sections/text-image-section';
