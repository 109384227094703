// Regular
@font-face {
  font-family: 'Euclid Circular';
  font-weight: 400;
  font-style: normal;
  src: url('../../../fonts/EuclidCircularA-Regular.otf') format('opentype'),
    url('../../../fonts/EuclidCircularA-Regular.woff') format('woff');
}

// Regular italic
@font-face {
  font-family: 'Euclid Circular';
  font-weight: 400;
  font-style: italic;
  src: url('../../../fonts/EuclidCircularA-RegularItalic.otf') format('opentype'),
    url('../../../fonts/EuclidCircularA-RegularItalic.woff') format('woff');
}

// Medium
@font-face {
  font-family: 'Euclid Circular';
  font-weight: 500;
  font-style: normal;
  src: url('../../../fonts/EuclidCircularA-Medium.otf') format('opentype'),
    url('../../../fonts/EuclidCircularA-Medium.woff') format('woff');
}

// Medium italic
@font-face {
  font-family: 'Euclid Circular';
  font-weight: 500;
  font-style: italic;
  src: url('../../../fonts/EuclidCircularA-MediumItalic.otf') format('opentype'),
    url('../../../fonts/EuclidCircularA-MediumItalic.woff') format('woff');
}

// Semibold
@font-face {
  font-family: 'Euclid Circular';
  font-weight: 600;
  font-style: normal;
  src: url('../../../fonts/EuclidCircularA-Semibold.otf') format('opentype'),
    url('../../../fonts/EuclidCircularA-Semibold.woff') format('woff');
}

// Semibold italic
@font-face {
  font-family: 'Euclid Circular';
  font-weight: 600;
  font-style: italic;
  src: url('../../../fonts/EuclidCircularA-SemiboldItalic.otf') format('opentype'),
    url('../../../fonts/EuclidCircularA-SemiboldItalic.woff') format('woff');
}
