*,
*::before,
*::after {
  box-sizing: inherit;
}

html {
  scroll-behavior: smooth;
}

body {
  overflow-x: hidden;
  overflow-y: scroll;
  min-width: 320px;
  box-sizing: border-box;
  -webkit-overflow-scrolling: touch;
  overscroll-behavior-x: none;
  background-color: $white;
  color: $black;
  @include font();

  *::selection {
    // background: $gray3;
  }

  *:focus {
    outline-color: $primary;
  }

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

table,
tr,
td {
  border: none;
  border-collapse: collapse;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  @include font();
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  padding: 0;
  margin: 0;
  line-height: 1.3;
}

a {
  color: inherit;
  text-decoration: none;

  &:hover {
    color: $primary;
  }
}

button {
  padding: 0;
  background: transparent;
  border: none;
}

#react-app {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}
