.home-page {
  .home-content {
    padding: 0 80px;

    @include bp($tablet) {
      padding: 0 32px;
    }

    @include bp($mobile) {
      padding: 0;
    }
  }

  .content-layout__content {
    margin-top: 0;
    display: block;
    overflow: visible;
  }

  .home-page.common-menu {
    flex: 1;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    margin-top: 44px;

    @include bp($tabletLandscape) {
      justify-content: flex-start;
    }

    @include bp($tablet) {
      margin-top: 40px;
    }

    .common-menu__item {
      margin: 12px 16px;
      flex-basis: calc(25% - 32px);
      @include body-large-strong();
      &:hover {
        .menu-item__text {
          color: $primary;
        }
      }
      .menu-item__text {
        font-weight: 600;
        color: $black;
      }

      @include bp($tabletLandscape) {
        flex-basis: calc(50% - 32px);
      }

      @include bp($mobile) {
        flex-basis: 100%;
        margin: 0;

        &:not(:last-child) {
          margin: 0 0 24px;
        }
      }
    }
  }

  .home-page.banners {
    margin-top: 64px;
  }

  // TODO: Move to scss separate files when components are finalized
  .notices-board {
    margin-top: 68px;

    @include bp($mobile) {
      width: calc(100% + 48px);
      margin-left: -24px;
    }

    @include minbp($mobile) {
      border-radius: 32px;
      background-color: $gray1;
      box-shadow: $turnitShadow;
    }

    .notices-board__heading {
      display: flex;
      justify-content: space-between;

      @include bp($mobile) {
        margin-left: 24px;
      }

      @include minbp($mobile) {
        padding: 32px 32px 0;
      }

      .notices-board__heading-title {
        @include heading-h2;
        @include heading-underline;
        margin-right: 16px;
      }
    }

    .notices-board__item {
      display: flex;
      margin: 40px 32px 0;
      flex-direction: column;
      width: 100%;

      @include bp($tabletLandscape) {
        margin: 40px 24px 0;
      }

      @include bp($mobile) {
        margin: 40px 0 0;

        background: $background;
        box-shadow: $turnitShadow;
        border-radius: 32px;
        padding: 40px 16px;
      }

      .notices-board__item-title {
        @include heading-h3;
        margin-bottom: 24px;
      }

      .notices-board__item-body {
        @include body-regular;
      }

      .notices-board__item-footer {
        display: flex;
        margin-top: auto;
        padding-top: 60px;
        justify-content: space-between;
        align-items: center;

        @include bp($tablet) {
          padding-top: 38px;
        }
      }

      .notices-board__item-footer-date {
        @include body-small-strong();
      }

      .notices-board__item-footer-link {
        @include font(600);
        font-size: 16px;
        line-height: 20px;
        color: $primary;

        display: flex;
        align-items: center;

        &:hover {
          color: $black;
        }

        > svg {
          margin-left: 4px;
        }
      }
    }

    .swiper-container {
      width: 100%;
      padding-bottom: 12px;

      @include bp($mobile) {
        padding: 0 24px 12px;
      }

      .swiper-wrapper {
        list-style: none;
        padding: 0;

        .swiper-slide {
          width: 100%;
          display: flex;
          height: auto;
        }
      }

      .swiper-controls {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 24px;
        position: relative;
        z-index: 10;

        @include bp($mobile) {
          margin-top: 16px;
        }

        &.hidden {
          display: none;
        }

        .swiper-button,
        .swiper-button {
          &-prev,
          &-next {
            display: flex;
            cursor: pointer;

            &.swiper-button-disabled {
              opacity: 0.5;
              cursor: default;
            }
          }
        }

        .swiper-pagination {
          position: relative;
          display: flex;

          &-container {
            background: $white;
            padding: 8px;
            border-radius: 32px;
            margin: 0 8px;
          }

          .swiper-pagination-bullet {
            flex-shrink: 0;
            margin: 0 4px;
            transition: all 0.2s;

            &-active-main {
              background: $primary;
            }
          }
        }
      }
    }
  }
}
