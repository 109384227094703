.turnit__search {
  width: 100%;
  max-width: 1440px;
  padding: 0 56px;
  margin: 0 auto;
  border-radius: 16px;
  z-index: 1;

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  @include bp($mobile) {
    padding-right: 16px;
    padding-left: 16px;
    height: auto;
  }

  @include bp($mobileXS) {
    padding-top: 32px;
    padding-right: 16px;
    padding-left: 16px;
    padding-bottom: 0;

    top: 0;
    transform: translate(-50%, 0);
  }

  .heading {
    margin-bottom: 24px;
    color: $searchTitleColor;
  }

  #turnit-search-container {
    border-radius: 16px;
    overflow: hidden;
    min-height: 112px;

    @include bp(999) {
      min-height: 64px;
    }
  }
}
