.shared-header__currency-selector,
.shared-header__locale-selector {
  > ._tippy > .shared-icon-button {
    > ._label {
      text-transform: uppercase;
    }

    > ._icon {
      flex-shrink: 0;

      > svg {
        max-width: 20px;
        max-height: 20px;
      }
    }
  }

  &.-mobile {
    width: 100%;

    > .collapsible {
      background: $navBackgroundColor;

      > .collapsible-content-wrapper {
        width: 100%;

        > .collapsible-label {
          height: 24px;
          border: none;
          padding: 0;
          text-align: left;

          > ._label {
            text-transform: uppercase;
            font-size: 18px;
            line-height: 24px;
            font-weight: 600;
            color: $navTextColor;
          }

          > ._icon {
            height: 20px;
            width: 20px;
            margin: 0;
            margin-right: 8px;
            flex-shrink: 0;

            > svg {
              height: 100%;
              width: 100%;
            }
          }
        }

        > .collapsible-content {
          margin-top: 24px;

          ul {
            margin-bottom: 24px;

            > li {
              color: $navTextColor;

              &:not(:last-child) {
                margin-bottom: 16px;
              }
            }
          }

          .radio-button__input .radio-button__check {
            background-color: $navTextColor;

            &.-checked {
              background-color: $navTextColor;

              &::after {
                background: $navBackgroundColor;
              }
            }
          }
        }
      }
    }
  }
}

.currency-selection-tippy,
.locale-selection-tippy {
  .radio-button:not(:last-child) {
    margin-bottom: 24px;
  }
}
