.radio-button {
  display: flex;
  cursor: pointer;
  flex: 1 0 50%;
  align-items: center;
  cursor: pointer;

  .radio-button__input {
    position: relative;

    .radio-button__check {
      width: 100%;
      height: 100%;

      position: absolute;
      top: 3px;
      bottom: 3px;
      left: 0;
      margin: auto 0;
      background-color: $gray2;
      border-radius: 50%;

      &.-checked {
        background-color: $primary;

        &:after {
          display: block;
        }
      }

      &:after {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        right: 0;
        margin: auto;

        display: none;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background: white;
      }
    }
  }

  .radio-button__label {
    cursor: pointer;
    margin-left: 8px;
  }
}
