.app-popup {
  @include minbp(768) {
    display: none;
  }
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: unset;
  background-color: $white;
  padding: 8px;
  display: flex;
  z-index: 6;
  justify-content: flex-start;
  align-items: center;
  box-shadow: $shadowMedium;

  .title {
    font-size: 12px;
    font-weight: 600;
    line-height: 16px;
  }
  .text {
    font-size: 10px;
    font-weight: 400;
    line-height: 12px;
  }
  .inner-wrapper {
    padding-right: 8px;
  }
  .icon-wrapper {
    margin-right: 8px;
    img {
      height: 40px;
      width: 40px;
    }
  }
  .close-icon {
    color: $black;
    margin-right: 8px;
    svg {
      height: 16px;
      width: 16px;
    }
  }
  a {
    padding: 4px 12px;
    margin-left: auto;
    border: 1px solid $primary;
    @include body-small-strong;
    color: $white;
    border-radius: 14px;
    background-color: $primary;
  }
}
