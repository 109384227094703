.feedback-form-page {
  &.-success {
    display: flex;
    align-items: center;

    min-height: 75vh;
    .heading {
      display: none;
    }
    .content-layout__content {
      padding-left: 0;
      margin-top: 0;
    }
  }
  .success-view {
    display: flex;
    justify-content: center;
    align-items: center;
    .heading {
      display: none;
    }
    .title {
      @include heading-h3;
      text-align: center;
    }
    .success-text {
      @include body-large;
      text-align: center;
      white-space: break-spaces;
    }
    .icon-wrapper {
      margin: 36px 0 46px;
      svg {
        display: block;
        margin: 0 auto;
      }
    }
  }

  .content-layout__content {
    padding-left: 165px;
    @include bp($tablet) {
      padding-left: 0;
    }
  }
  .textarea {
    max-width: 960px;
    max-height: 194px;
  }

  form {
    max-width: 960px;
    .checkbox {
      justify-content: initial;
    }
  }
  .section__title {
    margin-bottom: 40px;
  }
  .fields-wrapper {
    &:not(:first-of-type) {
      margin-top: 70px;
    }
  }
  .inputs-wrapper {
    display: flex;
    flex-wrap: wrap;
    > :nth-child(odd) {
      margin-right: 24px;
      @include bp($tablet) {
        margin-right: 0;
      }
    }
  }

  .radio-buttons {
    display: flex;
    @include bp($tablet) {
      flex-direction: column;
    }
    .radio-button {
      flex: initial;
      margin-right: 32px;
      .radio-button__input {
        .radio-button__check {
          background-color: $gray2;
          &.-checked {
            background-color: $primary;
          }
        }
      }
      @include bp($tablet) {
        &:not(:last-child) {
          margin-bottom: 20px;
        }
      }
    }
  }

  .additional-notice {
    background: $gray1;
    border-radius: 16px;
    padding: 32px;
    margin: 80px 0;
    max-width: 960px;

    .subtitle {
      @include heading-h3;
    }
    ul {
      list-style: none;
      padding-left: 0;
      li {
        margin: 16px 0;
        @include body-regular;
      }
    }
    ul li::before {
      display: inline-block;
      content: '\A';
      background-color: $primary;
      height: 12px;
      width: 12px;
      padding: 4px;
      border-radius: 4px;
      margin-right: 12px;
    }
  }
  .checkbox {
    @include body-regular;
  }
  .complaint-type-wrapper {
    column-count: 2;
    column-gap: 50px;
    > .checkbox,
    ._label {
      margin-bottom: 24px;
    }
    @include bp($tablet) {
      column-count: 1;
    }
  }
  .button {
    padding: 12px 54px;
    margin-left: auto;
    &:not(:disabled):hover {
      background-color: $black;
      opacity: 1;
    }
    @include bp($tablet) {
      width: 100%;
    }
  }

  .file-input-container {
    margin-bottom: 80px;
    .dropzone {
      width: 100%;
      padding: 32px;
      border: 2px dashed $gray3;
      background: transparent;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      min-height: 170px;
      border-radius: 4px;
      @include bp($tablet) {
        padding: 24px 32px;
      }
    }
    .file {
      @include body-regular-strong;
      color: $gray2;
    }
    .placeholder-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      svg {
        color: $gray3;
      }

      .placeholder {
        @include body-regular-strong;
        margin-top: 25px;
        color: $gray4;
        text-align: center;
      }
      .max-size {
        @include body-small;
        margin-top: 8px;
        color: $gray4;
      }
    }
  }
}
