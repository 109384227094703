.content__divider {
  margin: 48px 0;
  width: 100%;

  &--default {
    border-radius: 8px;
    height: 16px;
    background: linear-gradient(to right, $primary, rgba(255, 255, 255, 0));
    border: none;
    @include bp($tablet) {
      height: 8px;
    }
  }

  &.-desktop {
    @include bp($mobile) {
      display: none;
    }
  }

  &.-mobile {
    width: calc(100% + 48px);
    margin-left: -24px;
    margin-right: -24px;

    @include minbp($mobile) {
      display: none;
    }
  }
}
