.banners {
  margin: 0 -8px;
  &.banner-group__large {
    display: flex;
    flex-wrap: wrap;
    @include minbp($tablet) {
      > div {
        flex-basis: calc(33% - 16px);
      }
      > :first-child,
      > :nth-child(2) {
        flex-basis: calc(50% - 16px) !important;
      }
    }

    .banner {
      min-height: 300px;
      flex-basis: 100%;
      margin: 16px 8px 0;
      min-width: 315px;
      @include minbp($tablet) {
        flex: 1;
      }
    }
  }
}
