.faq-page {
  .topics {
    padding: 0 11px;
  }

  .topic {
    padding-bottom: 80px;

    .topic__title {
      margin-bottom: 40px;
    }
  }
}
