.content__steps-section {
  ol {
    list-style: none;
    counter-reset: li;
    padding-left: 0;

    li {
      counter-increment: li;
      padding: 24px 0;
      display: flex;
      align-items: center;
      @include heading-subtitle;

      &:before {
        content: counter(li);
        min-width: 72px;
        height: 72px;
        margin-right: 24px;
        border-radius: 16px;
        color: $black;
        background-color: $white;
        box-shadow: $shadowMedium;
        display: flex;
        align-items: center;
        justify-content: center;
        @include heading-h2;
      }
    }
  }
}
