.content__text-section {
  margin-top: 80px;

  .subtitle {
    @include heading-h3;
    padding-bottom: 24px;

    @include bp($tablet) {
      padding-top: 20px;
    }
  }

  iframe,
  img {
    max-width: 100%;
  }

  span {
    &:not(:empty) {
      @include body-large;
    }
  }

  p {
    @include body-large;
  }

  b * {
    font-weight: 600;
  }

  ul,
  ol {
    list-style: none;
    padding-left: 0;
  }

  ul {
    li {
      margin: 16px 0;
      @include body-large;
      white-space: normal;

      &::before {
        display: inline-block;
        content: '\A';
        background-color: $primary;
        height: 12px;
        width: 12px;
        padding: 4px;
        border-radius: 4px;
        margin-right: 12px;
      }
    }
  }

  ol {
    counter-reset: li;

    li {
      counter-increment: li;
      padding: 24px 0;
      display: flex;
      align-items: center;
      @include heading-subtitle;

      &:before {
        content: counter(li);
        min-width: 40px;
        height: 24px;
        margin-right: 24px;
        border-radius: 8px;
        background-color: $primary;
        display: flex;
        align-items: center;
        justify-content: center;
        @include body-small-strong($white);
        margin-top: 8px;
        margin-bottom: auto;
      }
    }
  }
}
