.popup-alerts {
  position: relative;
  width: 100%;
  transform: translateX(-50%);
  position: absolute;
  left: 50%;
  top: calc(#{$headerDesktopHeight} + 21px);

  max-width: 1440px;
  z-index: 201;

  padding: 0 10px;
}
