footer.shared-footer {
  position: relative;
  padding-top: 100px;
  margin-top: 48px;
  background: $gray1;

  &.-divider-default {
    padding-top: 80px;
  }

  @include bp($mobile) {
    padding-top: 80px;
    top: 0;
    margin-top: 96px;
  }

  .divider {
    position: absolute;
    top: -24px;
    left: 0;

    @include bp($tablet) {
      top: -16px;
    }

    &--ltg {
      top: 0;
      transform: translate(0, -50%);
    }

    &--default {
      width: 100%;
      border-radius: 8px;
      height: 16px;
      top: -56px;
      background: linear-gradient(to right, $primary, rgba(255, 255, 255, 0));
      border: none;

      @include bp($tablet) {
        height: 8px;
      }
    }

    &.-desktop {
      width: 100%;
      @include bp($tablet) {
        display: none;
      }
    }

    &.-mobile {
      width: 100%;
      @include minbp($tablet) {
        display: none;
      }
    }
  }

  .footer-menu {
    padding: 0 0 30px;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(215px, 1fr));
    grid-row-gap: 50px;
    grid-column-gap: 32px;

    @include minbp($mobileXS) {
      padding: 0 30px 30px;
    }
  }

  .footer-menu-wrapper {
    display: inline-block;
  }

  .footer-menu__item,
  .footer-menu__item > button {
    font-size: 18px;
    line-height: 24px;
    color: $black;
    letter-spacing: 0.1px;
    font-weight: 600;
    margin-bottom: 16px;
    display: inline-block;

    @include bp($mobile) {
      margin-bottom: 0;
    }
  }

  a.footer-menu__item {
    :hover {
      color: $primary;
    }
  }

  .footer-menu__child {
    margin-top: 32px;
    color: $black;

    .menu-item__text,
    .menu_item__icon {
      color: $black;
    }

    &,
    > button {
      display: inline-block;
      text-align: left;
    }
  }

  .contact-wrapper {
    > :not(:first-child) {
      margin-top: 28px;
    }
  }
}

.shared-footer-menu {
  > ul {
    padding: 0;

    > li > ul {
      padding: 0;
      list-style: none;
    }
  }
}
