.business-form-page {
  .heading {
    @include bp($tablet) {
      span {
        @include heading-h3;
      }
    }
  }
  form {
    padding-left: 170px;
    @include bp($tablet) {
      padding-left: 0;
    }
  }
  .inputs-container {
    display: flex;
    flex-wrap: wrap;
    margin-top: 40px;
    margin-bottom: 56px;
    > :nth-child(odd) {
      margin-right: 24px;
      @include bp($tablet) {
        margin-right: 0;
      }
    }
  }
  .button {
    width: 100%;
    max-width: 954px;
    &:not(:disabled):hover {
      background-color: $black;
      opacity: 1;
    }
  }

  &.-success {
    display: flex;
    align-items: center;
    min-height: 75vh;

    .heading {
      display: none;
    }
    .content-layout__body {
      margin: 0 auto;
    }

    .success-view {
      display: flex;
      justify-content: center;
      align-items: center;
      .title {
        @include heading-h3;
        text-align: center;
      }
      .success-text {
        @include body-large;
        text-align: center;
        white-space: break-spaces;
      }
      .icon-wrapper {
        margin: 36px 0 46px;
        svg {
          display: block;
          margin: 0 auto;
        }
      }
    }
  }

  .radio-buttons-wrapper {
    display: flex;
    flex-direction: row;
    margin: 26px 0 72px;
    @include bp($mobile) {
      flex-direction: column;
      > :first-child {
        margin-bottom: 16px;
      }
    }
  }
  .fields-wrapper {
    > ._label {
      @include heading-pre-title($gray5);
    }
  }
}
