.menu-mobile-popup-content {
  list-style: none;
  padding: 0;
  margin: 0;

  .subtitle {
    font-size: 24px;
    line-height: 32px;
    color: $black;
    font-weight: 600;
  }

  .header-menu__child {
    font-size: 18px;
    line-height: 24px;
    color: $gray5;
    letter-spacing: 0.1px;
    font-weight: 600;
    margin: 32px 0;
  }

  svg {
    margin-right: 4px;
  }
}
