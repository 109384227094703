.stations-page {
  .heading.h2 {
    @include bp($mobile) {
      @include heading-h3;
    }
  }
  .alphabet {
    width: 100%;
    display: flex;
    justify-content: space-between;
    @include bp($mobile) {
      justify-content: space-around;
    }
    .character {
      @include body-large($gray3);
      &.active {
        color: $black;
        cursor: pointer;
      }
      &:not(.active) {
        pointer-events: none;
      }
      @include bp($mobile) {
        &:not(.active) {
          display: none;
        }
      }
    }
  }

  .content-layout__content {
    flex-direction: column;

    .stations-container {
      display: flex;
      margin-top: 60px;
      padding-top: 20px;
      .stations-wrapper {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
      }
      @include bp($mobile) {
        &:first-child {
          margin-top: 0;
        }
      }
      @include bp($tablet) {
        flex-direction: column;
      }
    }

    .station-letter {
      font-size: 128px;
      line-height: 96px;
      font-weight: 600;
      color: $gray5;
      margin-right: 225px;
      min-width: 100px;
      @include bp($tablet) {
        margin-right: 0;
        line-height: 128px;
        margin-bottom: 16px;
      }
    }
  }
}
