.shared__header-basket-button {
  display: flex;
  align-items: center;
  margin: 0 16px 0 auto;
  min-width: 80px;

  @include bp(1111px) {
    margin-right: 0;
  }

  .basket {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;

    > .combined-icons {
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      height: 32px;
      width: 32px;
      margin-right: 8px;

      > .icon {
        height: 18px;
        width: 18px;
        margin-top: 4px;

        > svg {
          height: 100%;
          width: 100%;
        }
      }

      > .counter-icon {
        position: absolute;
        font-style: normal;
        font-weight: bold;
        font-size: 10px;
        line-height: 12px;
        letter-spacing: 0.1px;
        left: 16px;
        top: 2px;
        color: #fff;

        display: flex;
        align-items: center;
        justify-content: center;
        height: 14px;
        width: 14px;
        background-color: $black;
        border-radius: 30px;

        > .checkmark-icon {
          height: 10px;
          width: 10px;

          > svg {
            height: 100%;
            width: 100%;
          }
        }
      }
    }
  }

  .timer {
    cursor: pointer;
    color: $navTextColor;
  }
}
