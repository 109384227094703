:root {
  // Ignore @reach-ui default css
  --reach-checkbox: 1;
}

.checkbox {
  display: flex;
  justify-content: center;

  &:not(.disabled) {
    cursor: pointer;
  }

  &.disabled {
    cursor: not-allowed;
    opacity: 0.75;
  }

  input {
    display: none;
  }

  .checkbox__container {
    margin-right: 8px;
    padding-top: 4px;
  }

  .checkbox__checkmark {
    border-radius: 4px;
    background-color: $gray2;
    position: relative;

    .checkbox__check {
      display: inline;

      transform: rotate(45deg) translate(-50%, -50%);
      position: absolute;
      top: 50%;
      left: 25%;

      border-bottom-width: 2px;
      border-right-width: 2px;
      border-bottom-style: solid;
      border-right-style: solid;
      border-color: transparent;
    }

    &.medium {
      height: 16px;
      width: 16px;

      .checkbox__check {
        width: 6px;
        height: 10px;
      }
    }

    &.large {
      height: 24px;
      width: 24px;

      .checkbox__check {
        width: 8px;
        height: 12px;
        top: 10px;
        left: 7px;
      }
    }

    &.checked:not(.disabled) {
      background-color: $primary;

      .checkbox__check {
        border-color: $white;
      }
    }

    &.disabled {
      background-color: $gray5;

      .checkbox__check {
        border-color: $white;
      }
    }
  }

  .checkbox__children {
    @include body-regular();
  }
}
