.shared-icon-button {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: none;
  background: none;
  padding: 0;

  > ._icon {
    display: flex;
    justify-content: center;
    align-items: center;

    &:not(:only-child) {
      margin-right: 8px;
    }

    > svg {
      width: 20px;
      height: 20px;
      max-width: 20px;
      max-height: 20px;
    }
  }

  > ._label {
    font-size: 16px;
    line-height: 20px;
    color: $navTextColor;
    font-weight: 400;
  }
}
