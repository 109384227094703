.side-panel-content-wrapper {
  .image-wrapper {
    display: flex;
    max-width: 100%;
    min-height: 340px;
    max-height: 340px;
    position: relative;
    &:before {
      background: linear-gradient(180deg, rgba(0, 0, 0, 0.9) 0%, rgba(0, 0, 0, 0) 40%);
      top: 0%;
      content: '';
      left: 0;
      right: 0;
      bottom: 0;
      position: absolute;
    }

    img {
      object-fit: cover;
      object-position: center;
      width: 100%;
    }
    .station-name-wrapper {
      display: flex;
      position: absolute;
      top: 40px;
      left: 80px;
      width: calc(100% - 160px);
      @include heading-h2($white);
      z-index: 1;

      @include bp($mobile) {
        left: 24px;
        width: calc(100% - 48px);
      }

      .close-button {
        color: $white;
        margin-left: auto;
        cursor: pointer;
      }
    }
  }
  .lower-content {
    position: relative;
    padding: 52px 80px;
    @include bp($mobile) {
      padding: 36px 24px;
    }
    .destination-selection {
      display: flex;
      flex-direction: row;
      margin-top: 16px;
      svg {
        color: $white;
      }
    }

    select {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      width: 100%;
      border: none;
      background: $gray1;
      border-radius: 8px 0px 0px 8px;
      cursor: pointer;
      padding: 12px 36px 12px 16px;
      @include body-regular($gray5);
    }

    .button {
      border-radius: 0px 8px 8px 0px;
      padding: 15px 20px;
    }

    > svg {
      position: absolute;
      transform: translate(0, -50%);
      top: 0;
      left: 0;
    }

    .additional_image {
      margin-top: 50px;
      img {
        border-radius: 16px;
        object-fit: cover;
        object-position: center;
        width: 100%;
      }
    }
    .info-section {
      display: flex;
      border-radius: 16px;
      overflow: hidden;
      background-color: $background;

      @include bp($mobile) {
        flex-direction: column;
      }

      .contacts-wrapper,
      .map-wrapper {
        width: 50%;
        height: 152px;
        img {
          object-fit: cover;
          object-position: center;
          width: 100%;
        }

        @include bp($mobile) {
          width: 100%;
          height: 160px;
        }
      }

      .contacts-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 24px 30px;
        > :not(:last-child) {
          margin-bottom: 16px;
        }
        @include bp($mobile) {
          height: 100%;
        }

        .contact {
          display: flex;
          @include body-regular;
          .icon {
            margin-right: 8px;
          }
        }
      }
      .map-wrapper {
        display: flex;
      }
    }

    .services-section {
      margin-top: 40px;
    }
    .services {
      display: flex;
      flex-wrap: wrap;
    }
    .subtitle {
      @include body-regular-strong;
      margin-top: 40px;
    }

    .select-search {
      label {
        display: block;
        @include body-regular-strong;
        margin-bottom: 16px;
      }
    }
    .service-wrapper {
      display: flex;
      width: 50%;
      margin-top: 16px;
      padding-right: 15px;
      @include bp($mobile) {
        width: 100%;
        padding-right: 0;
      }

      .service-name {
        @include body-regular;
      }
      .icon {
        margin-right: 8px;
        height: 24px;
        width: 24px;
      }
    }
  }
}
