.shared-toast-wrapper {
  max-height: 200px;
  margin: 0 auto 14px auto;
  max-width: 700px;
  padding: 0 30px;
  width: 100%;
  transition: all 250ms ease;

  .toast {
    pointer-events: all;
    border-radius: 4px;
    overflow: hidden;
    background: #fff;
    border-radius: 4px;
    box-shadow: $turnitShadow;

    .toast_content {
      width: 100%;
      min-height: 58px;
      cursor: pointer;
      display: flex;
      flex-direction: row;

      ._icon {
        color: $white;
        min-height: 60px;
        width: 60px;
        padding: 18px;
        position: relative;
        flex-shrink: 0;
        border-radius: 4px 0 0 4px;
        overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: center;

        // Fallback color
        background: $warning;

        svg,
        img {
          width: 24px;
          height: 24px;
          object-fit: contain;
          object-position: center;
        }
      }

      > ._text-content {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 12px;
        overflow: hidden;
        width: 100%;

        > ._content {
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 18px;
          letter-spacing: 0.1px;
          color: $black;
        }
      }
    }

    &._error ._icon {
      background: $danger;
    }

    &._warning ._icon {
      background: $warning;
    }

    &._success ._icon {
      background: $success;
    }
  }

  &.toast--anim-enter {
    opacity: 0;
    margin-bottom: 0;
  }

  &.toast--anim-enter-active {
    opacity: 1;
    margin-bottom: 14px;
  }

  &.toast--anim-exit {
    opacity: 1;
  }

  &.toast--anim-exit-active {
    opacity: 0;
    margin-bottom: 0;
  }
}
