.content-layout {
  .content-layout__content {
    display: flex;
    flex: 1;
    margin-top: 72px;
    overflow: hidden;

    @include bp($mobile) {
      margin-top: 40px;
      flex-direction: column;
    }
  }

  .content-layout__nav-wrapper {
    height: 100%;
    min-width: 320px;
    max-width: 320px;
    position: sticky;
    margin-right: 42px;
    top: 72px;

    @include bp($mobile) {
      margin-right: 0;
      margin-bottom: 40px;
      min-width: calc(100% + 48px);
      max-width: 100%;
      top: 0px;
      background: $white;
      padding: 15px 24px;
      margin: 0 -24px 0 -24px;
      z-index: 1;
    }
  }

  .content-layout__nav {
    position: sticky;
    top: 72px;
    display: flex;
    flex-direction: column;

    @include bp($mobile) {
      position: sticky;
      overflow-x: auto;
      flex-direction: row;
      margin-right: -24px;
      @include hide-scrollbar;
    }

    a {
      @include body-regular;
      color: $gray4;
      margin-bottom: 16px;
      cursor: pointer;

      &.active {
        @include body-regular-strong;
      }

      &:hover {
        color: $primary;
      }

      @include bp($mobile) {
        margin-bottom: 0;
        margin-right: 16px;
        white-space: nowrap;
      }
    }
  }

  .content-layout__body {
    flex: 1;
  }
}
