.gdpr__cookie-notice {
  position: fixed;
  z-index: 9999;
  bottom: 0;
  left: 0;
  right: 0;

  padding: 24px;
  background-color: $background;
  box-shadow: 0 0 15px -5px rgba(0, 0, 0, 0.75);

  @include bp($mobile) {
    padding-top: 42px;
  }

  .cookie-notice__close {
    position: absolute;
    top: 24px;
    right: 24px;
    display: flex;
    align-items: center;
    color: $black;
    svg {
      height: 24px;
      width: 24px;
    }
    @include bp($mobile) {
      top: 16px;
    }
  }

  .cookie-notice__buttons,
  .cookie-notice__manager {
    display: flex;
    flex-wrap: wrap;
    overflow: hidden;

    @include bp($mobile) {
      flex-direction: column;
    }
  }

  // Buttons
  .cookie-notice__buttons,
  .cookie-notice__manager {
    button.button:not(:last-child) {
      margin-right: 24px;
      margin-bottom: 8px;

      @include bp($mobile) {
        margin-right: 0;
        margin-bottom: 12px;
        width: 100%;
      }
    }
    button {
      height: 32px;
      border-radius: 4px;
      &.primary {
        padding: 0 39px;
      }
      &.secondary {
        &:hover {
          color: $black;
          border-color: $gray2;
          background-color: transparent;
          opacity: 1;
        }
        @include body-small-strong();
        padding: 0 39px;
        border: 1px solid $gray2;
      }
    }
  }

  // Manage
  .cookie-notice__manager {
    .checkbox__container {
      padding-top: 0;
    }
    .checkbox {
      align-items: center;
    }
    fieldset {
      border-color: transparent;
      display: flex;
      padding: 0;
      margin-right: 24px;
      margin-left: 0;

      @include bp($mobile) {
        margin-right: 0;
        margin-bottom: 16px;
        display: flex;
        flex-wrap: wrap;
      }

      label {
        margin-bottom: 4px;

        &:not(:last-child) {
          margin-right: 26px;
        }
      }
    }
  }

  .cookie-notice__body {
    margin-bottom: 24px;

    @include bp($mobile) {
      margin-bottom: 16px;
    }
  }

  .cookie-notice__title {
    @include body-small-strong();
  }

  .cookie-notice__description {
    @include body-small();
    margin-bottom: 0;
    margin-top: 14px;

    > a {
      // Readmore page link
      color: $black;
      font-weight: 600;
      text-decoration: underline;
    }
  }
}
