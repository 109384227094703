header.shared-header {
  $mobileBp: 1111px;

  position: relative;
  background-color: $navBackgroundColor;
  height: $headerDesktopHeight;
  width: 100%;
  flex-shrink: 0;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.1);

  @include bp($mobile) {
    height: $headerMobileHeight;
  }

  > ._content {
    height: 100%;
    display: flex;
    padding: 0 56px;
    align-items: center;
    justify-content: space-between;

    @include bp($mobileBp) {
      padding: 0 16px;
      align-items: center;

      .shared__header-basket-button {
        height: 36px;
      }
    }

    > .shared-header__account-area.-mobile {
      margin-bottom: 0;

      @include minbp(1111) {
        display: none;
      }

      > ._link,
      > ._link > * > ._label {
        font-size: 16px;
        font-weight: normal;
      }
    }

    > ._logo {
      height: 48px;
      display: flex;
      flex-shrink: 1;
      min-width: 0;
      margin: 0 4px 0 0;
      padding: 9px 0;

      > img {
        height: 100%;
        max-width: 100%;
        object-fit: scale-down;
      }
      &._large {
        padding: 0;
        @include bp($mobileBp) {
          height: 40px;
        }
      }
    }

    ._hamburger-btn {
      margin-left: 10px;
      border: none;
      height: 36px;
      background: none;

      @include minbp($mobileBp) {
        display: none;
      }
    }

    > .header-menu {
      flex: 1;
      display: flex;
      margin: 0 12px 0 32px;
      justify-content: flex-start;
      align-items: center;

      > .menu-item__text_icon img {
        display: none;
      }

      @include bp($mobileBp) {
        display: none;
      }

      > .header-menu__item,
      > .header-menu__item > button {
        margin: 0 16px;
        cursor: pointer;
        font-size: 18px;
        line-height: 24px;
        color: $navTextColor;
        letter-spacing: 0.1px;
        font-weight: 600;
        user-select: none;

        &.active {
          position: relative;

          &:after {
            content: '';
            position: absolute;
            bottom: -6px;
            right: 0;
            left: 0;
            height: 2px;
            background: $primary;
          }
        }

        &:hover {
          opacity: 0.8;

          > .menu-item__text:hover {
            color: $navTextColor;
          }
        }
      }
    }
  }
}

// Menu item styles
.menu-item__text {
  border: none;
  padding: 0;
  margin: 0;
  background: none;
  cursor: inherit;
  text-align: left;
  color: inherit;
}
.header-account-dropdown {
  display: flex;
  flex-direction: column;
  gap: 16px;
  min-width: 200px;

  > ._link {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
  ._icon {
    svg {
      width: 20px;
      height: 20px;
    }
  }

  a {
    font-size: 16px;
    font-weight: 400;
    color: $black;
  }
  .link-wrapper {
    ._link {
      display: flex;
      align-items: center;
      padding-top: 16px;
      border-top: 1px solid $gray2;
      ._icon {
        margin-right: 8px;
        height: 20px;
        width: 20px;
      }
    }
  }
}
