@keyframes fill-progress {
  from {
    width: 0%;
  }
  to {
    width: 100%;
  }
}

.page-loader {
  $pageLoaderHeight: 3px;
  transition: all 500ms $transition 500ms;
  height: $pageLoaderHeight;

  position: absolute;
  overflow: hidden;
  z-index: 100;
  opacity: 0;

  top: $headerDesktopHeight;
  left: 0;
  right: 0;

  @include bp($mobile) {
    top: $headerMobileHeight;
  }

  .page-loader__bar {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: $pageLoaderHeight;
    background: $gray4;
  }

  .page-loader__progress {
    height: $pageLoaderHeight;
    background: $primary;
    animation: fill-progress;
    animation-timing-function: cubic-bezier(0.5, 0.5, 0, 1);
    animation-fill-mode: forwards;
    animation-duration: 1000ms;
  }

  &:not(.finished) {
    opacity: 1;

    .page-loader__progress {
      animation-duration: 5000ms;
    }
  }

  .page-loader__backdrop {
    position: absolute;
    top: $pageLoaderHeight;
    bottom: 0;
    left: 0;
    right: 0;
  }
}
