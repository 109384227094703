.sidebar {
  position: fixed;
  top: 286px;
  height: fit-content;
  margin-right: 100px;
  background-color: transparent;
  z-index: 2;
  @include bp($tablet) {
    position: sticky;
    display: flex;
    top: 0;
    margin-right: -20px;
    margin-left: -20px;
    overflow-x: auto;
    padding: 20px 0;
    background-color: $background;
    z-index: 2;

    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    &::-webkit-scrollbar {
      display: none;
    }
    > :first-child {
      padding-left: 20px;
    }
  }

  .sidebar-topic {
    margin: 16px 0;
    cursor: pointer;
    width: fit-content;
    @include body-regular;

    &.active {
      @include body-regular-strong;
    }
    &:first-of-type {
      margin-top: 0;
    }
    @include bp($tablet) {
      white-space: nowrap;
      font-size: 14px;
      padding-right: 24px;
      margin: 0;
    }
  }
}
