main.page {
  min-height: 100vh;

  &.notices-page,
  &.content-page,
  &.faq-page {
    .content-layout__content {
      overflow: visible;
    }
    .content-layout__body {
      overflow: hidden;
    }
  }
}
